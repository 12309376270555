import React from 'react'

class MainPage extends React.Component {
    constructor(props) {
        super(props)
        this.props = props
    }

    render() {
        return (
            <div className="mainpage">
                <h1><span>THIS</span> <span>COULD</span> <span>BE</span> <span>SOMETHING</span> <span className="orange">GREAT</span><span>!</span></h1>
            </div>
        )
    }
}

export default MainPage