import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import './scss/index.scss'
import AppRouter from './routers/AppRouter'

moment.updateLocale('en', {
  week: {
    dow : 1, // Monday is the first day of the week.
  }
});

ReactDOM.render(<AppRouter/>, document.getElementById('app'))